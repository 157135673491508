import { ChangeEvent, useCallback, useState } from "react";
import AddCircle from "@mui/icons-material/AddCircle";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Divider,
  Menu,
  MenuItem,
  popoverClasses,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { bindMenu, bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";
import { useDebouncedCallback } from "use-debounce";
import { useAppContext } from "../../../../../context/AppContext";
import { useAssetsDataContext } from "../../../shared/AssetsDataContext";
import { BaseGeofenceFilters } from "./components/BaseGeofenceFilters";
import { GeofenceQueryBuilder } from "./components/GeofenceQueryBuilder";
import { MoreGeofenceFilters } from "./components/MoreGeofenceFilters";

const menuActionsOptions = [
  {
    title: "New Geofence",
    action: "create",
    icon: <AddCircle fontSize="inherit" />,
  },
];

export const GeofenceFilters = () => {
  const {
    state: {
      appConfig,
      theme: { theme },
    },
  } = useAppContext();

  const {
    assetsSearchInput,
    setSelectedGeofence,
    setGeofenceForUpdate,
    setIsDrawingGeofence,
    setIsAssetsDrawerOpen,
    setIsFiltersDrawerOpen,
    setAddGeofenceDrawerOpen,
    addGeofenceDrawerOpen,
    isDrawingGeofence,
    setIsGeofenceDashboardVisible,
    restoreGeofencePreviousFilters,
    resetGeofenceFiltersToDefault,
    setGeofenceNameInput,
    geofenceNameInput,
  } = useAssetsDataContext();

  const [searchValue, setSearchValue] = useState(geofenceNameInput);

  const popupState = usePopupState({
    variant: "popover",
    popupId: "more-geofence-actions-popup",
  });

  const handleNewGeofence = () => {
    setSelectedGeofence(null);
    setGeofenceForUpdate(null);
    if (isDrawingGeofence) {
      restoreGeofencePreviousFilters();
    } else {
      resetGeofenceFiltersToDefault();
    }
    setIsDrawingGeofence(!isDrawingGeofence);
    setIsAssetsDrawerOpen(false);
    setIsFiltersDrawerOpen(false);
    setAddGeofenceDrawerOpen(!addGeofenceDrawerOpen);
    setIsGeofenceDashboardVisible(false);
  };

  const handleActionMenuClick = (action: string) => {
    if (!action) return;
    if (action === "create") handleNewGeofence();
  };

  const handleGeofenceSearch = useDebouncedCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setGeofenceNameInput(e.target.value);
    },
    appConfig.debounceTimeShort
  );

  const onGeofenceSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.target.value);
      handleGeofenceSearch(e);
    },
    [handleGeofenceSearch]
  );

  const borderColor = theme === "dark" ? "gray" : "var(--border-color)";

  return (
    <div>
      <Box
        className="h-14 px-2.5 flex justify-between items-center"
        sx={{
          borderBottom: `1px solid ${borderColor}`,
        }}
      >
        <ListAltIcon
          className="hover: cursor-pointer"
          {...bindTrigger(popupState)}
        />
        <Menu
          {...bindMenu(popupState)}
          id="filters-operations-menu"
          data-testid="filters-operations-menu"
          className="!text-xs"
          sx={{
            [`& .${popoverClasses.paper}`]: {
              backgroundColor: "var(--background)",
              minWidth: "240px",
              maxWidth: "280px",
            },
            "& .MuiList-root": {
              display: "flex",
              flexDirection: "column",
              width: "100%",
              fontSize: ".75rem",
              "*": {
                fontWeight: "500 !important",
              },
            },
          }}
        >
          <div>
            <Typography
              key="actions-title"
              className="px-4 !text-inherit text-menu-group"
              variant="subtitle2"
            >
              Actions
            </Typography>
            {menuActionsOptions.map((option) => (
              <MenuItem
                key={option.title}
                className="flex !justify-between !text-inherit"
                onClick={() => handleActionMenuClick(option.action)}
              >
                <p>{option.title}</p>
                {option.icon}
              </MenuItem>
            ))}
          </div>
        </Menu>

        <TextField
          id="assets-search-field"
          label=""
          defaultValue={assetsSearchInput ?? ""}
          placeholder="Geofence Search"
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
          onChange={onGeofenceSearch}
          value={searchValue}
          sx={{
            width: "88%",
          }}
          data-testid="geofence-search-field"
        />
      </Box>
      <Box
        sx={{
          height: "calc(100% - 56px)",
        }}
        className="w-full p-5 absolute justify-center items-center overflow-x-hidden overflow-y-visible"
      >
        <BaseGeofenceFilters />
        <MoreGeofenceFilters />
        <GeofenceQueryBuilder />
      </Box>
    </div>
  );
};
