import { useMemo } from "react";
import { Card, Typography } from "@mui/material";
import {
  DistanceUnit,
  GetDashboardWidgetResponse,
  useGetDashboardWidgetQuery,
} from "../../../../../graphql/operations";
import {
  TripleGridWidget,
  TripleGridWidgetData,
} from "../../../../../shared/components/DashboardWidgets/TripleGridWidget/TripleGridWidget";
import { useSelectedOrg } from "../../../../../shared/hooks/useSelectedOrg";
import { formatNumber } from "../../../../../utils/formatters";
import { hasNoPermission } from "../../shared/utils";

export type DashboardWidgetAssetsMileageData = {
  low: AssetsMileageData;
  medium: AssetsMileageData;
  high: AssetsMileageData;
};

export type AssetsMileageData = {
  assetCount: number;
  percentage: number;
};

export interface DashboardWidgetAssetsMileage
  extends GetDashboardWidgetResponse {
  data: DashboardWidgetAssetsMileageData;
}

export interface AssetsMileageWidgetProps {
  widget: DashboardWidgetAssetsMileage;
}

type AssetMileageLabelMap = {
  [key: string]: string;
};

const sectionTitle: AssetMileageLabelMap = {
  low: "Low Mileage",
  medium: "Medium Mileage",
  high: "High Mileage",
};

const sectionLabelKilometers: AssetMileageLabelMap = {
  low: "0-48k Kilometers",
  medium: "48k-96k Kilometers",
  high: "96k+ Kilometers",
};

const sectionLabelMiles: AssetMileageLabelMap = {
  low: "0-30k Miles",
  medium: "30-60k Miles",
  high: "60k+ Miles",
};

export const pluralize = (count: number, noun: string, suffix = "s") =>
  `${noun}${count !== 1 ? suffix : ""}`;

export const mapWidgetData = (
  data: DashboardWidgetAssetsMileageData,
  odometerUnit: DistanceUnit
): TripleGridWidgetData[] => {
  if (!data) {
    return [];
  }

  return Object.entries(data).map(
    ([assetMileageCategory, assetMileageData]) => {
      return {
        title: sectionTitle[assetMileageCategory],
        columnOneContent:
          odometerUnit === DistanceUnit.Miles
            ? sectionLabelMiles[assetMileageCategory]
            : sectionLabelKilometers[assetMileageCategory],
        columnTwoContent: `${formatNumber(
          assetMileageData.assetCount
        )} ${pluralize(assetMileageData.assetCount, "Asset")}`,
        columnThreeContent: `${assetMileageData.percentage?.toFixed()}%`,
      };
    }
  );
};

export const AssetsMileageWidget: React.FC<AssetsMileageWidgetProps> = ({
  widget,
}) => {
  const currentOrg = useSelectedOrg();

  const odometerUnit =
    currentOrg?.distance_unit_preference ?? DistanceUnit.Kilometers;

  const title = widget.name ?? "";

  const {
    isLoading,
    data: getDashboardWidgetData,
    error: dashboardWidgetQueryError,
  } = useGetDashboardWidgetQuery(
    {
      input: {
        widgetId: widget?.id,
        orgId: currentOrg?._id ?? "",
      },
    },
    {
      enabled: Boolean(currentOrg),
    }
  );

  const data = useMemo(() => {
    const parsedData = JSON.parse(
      getDashboardWidgetData?.getDashboardWidget?.data ?? null
    );
    const mappedData = mapWidgetData(parsedData, odometerUnit);

    return mappedData;
  }, [getDashboardWidgetData, odometerUnit]);

  console.log(JSON.stringify(getDashboardWidgetData));

  return (
    <Card
      sx={{
        boxShadow: "none",
        minHeight: "100%",
        ...(isLoading || dashboardWidgetQueryError
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem 2rem",
            }
          : {}),
      }}
      data-testid="dashboard-widget--assets-mileage"
    >
      {dashboardWidgetQueryError ? (
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          className="text-error !mb-3.5"
        >
          There was an error when fetching the data for the assets mileage
          widget! Please try again later.
        </Typography>
      ) : (
        <TripleGridWidget
          title={title}
          isLoading={isLoading}
          data={data}
          noPermission={hasNoPermission(getDashboardWidgetData)}
        />
      )}
    </Card>
  );
};
