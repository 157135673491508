import {
  ChangeEventHandler,
  FC,
  memo,
  KeyboardEvent,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useForm } from "react-hook-form";
import { TextFieldElement } from "react-hook-form-mui";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  FormControlLabel,
  Checkbox,
  ThemeProvider,
  Grid,
  Box,
  DialogContentText,
} from "@mui/material";
import {
  TextButton,
  Button as SubmitButton,
} from "../../../../../shared/components/Button";
import Text from "../../../../../shared/components/Text";
import { useFormTheme } from "../../../../../shared/hooks/theme/useFormTheme";
import { Modes } from "../../../MapView/Shared/FiltersDrawer/FiltersDrawer";

const BREAKPOINTS = { xs: 12 };
export interface ModalModeMap {
  [key: string]: {
    title: string;
    action: () => void;
    disabled?: boolean;
  };
}

interface FilterModalProps {
  mode: string;
  open: boolean;
  onClose: () => void;
  modalModeMapping: ModalModeMap;
  name: string;
  isPublic: boolean;
  originalIsPublic?: boolean;
  setPublic: (val: boolean) => void;
  setName: (val: string) => void;
  isCreatingAssetFilter: boolean;
  isUpdatingAssetFilter: boolean;
  isDeletingAssetFilter: boolean;
  helperText: string;
}

//---------------------------
// Local Component
//---------------------------
const FilterModal: FC<FilterModalProps> = memo(
  ({
    onClose,
    open,
    mode,
    name,
    setName,
    originalIsPublic,
    isPublic,
    setPublic,
    helperText,
    modalModeMapping,
    isCreatingAssetFilter,
    isUpdatingAssetFilter,
    isDeletingAssetFilter,
  }) => {
    const [saveLoadingBtnText, setSaveLoadingBtnText] = useState(
      mode === "Save" ? "Saving..." : "Updating..."
    );
    const formTheme = useFormTheme();
    const { title, action, disabled } =
      modalModeMapping[mode as keyof ModalModeMap];
    const {
      control,
      formState: { isDirty },
      clearErrors,
      setError,
      getValues,
    } = useForm({
      defaultValues: { filterName: name },
    });

    const isLoading = useMemo(
      () =>
        isCreatingAssetFilter || isUpdatingAssetFilter || isDeletingAssetFilter,
      [isCreatingAssetFilter, isUpdatingAssetFilter, isDeletingAssetFilter]
    );
    const isEditing = useMemo(() => mode === Modes.Update, [mode]);
    const isDeleting = useMemo(() => mode === Modes.Delete, [mode]);
    const publicPropHasBeenChanged = useMemo(
      () => isPublic !== originalIsPublic,
      [isPublic, originalIsPublic]
    );
    const formIsDirty = useMemo(
      () => isDirty || publicPropHasBeenChanged,
      [isDirty, publicPropHasBeenChanged]
    );
    const isDisabled = useMemo(
      () =>
        isLoading ||
        !name.length ||
        disabled ||
        (isEditing && !formIsDirty) ||
        Boolean(helperText),
      [isLoading, name, disabled, isEditing, formIsDirty, helperText]
    );
    const actionPresentParticiple = useMemo(
      () => `${isDeleting ? "Deleting" : isEditing ? "Saving" : "Creating"}...`,
      [isDeleting, isEditing]
    );

    // Callbacks and Handlers
    const onFilterNameChangeHandler: ChangeEventHandler<HTMLInputElement> = (
      e
    ) => setName(e.currentTarget.value);

    const onFilterTypeChangeHandler: ChangeEventHandler<HTMLInputElement> = (
      e
    ) => setPublic(e.target.checked);

    const onFilterNameKeyDownHandler = useCallback(
      (e: KeyboardEvent<HTMLInputElement>) => {
        clearErrors("filterName");
        if (e.key === "Enter" && !disabled) {
          action();
        }
      },
      [disabled, clearErrors, action]
    );

    const clearErrorsAndAction = useCallback(() => {
      clearErrors("filterName");
      action();
    }, [clearErrors, action]);

    const onSaveHandler = useCallback(() => {
      if (!isDeleting) {
        if (helperText.length) {
          setError("filterName", {
            type: "manual",
            message: "This filter name already exists.",
          });
        } else if (!name.length && !getValues().filterName.length) {
          setError("filterName", {
            type: "manual",
            message: "Filter name is required",
          });
        } else clearErrorsAndAction();
      } else clearErrorsAndAction();
    }, [
      name,
      helperText,
      isDeleting,
      setError,
      getValues,
      clearErrorsAndAction,
    ]);

    return (
      <Dialog
        data-testid={`${mode}-asset-filter-dialog`}
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            padding: "32px 24px 24px 24px",
            width: "100%",
            maxWidth: "680px !important",
          },
        }}
      >
        <DialogTitle className="flex justify-between items-center !p-0 !pb-[32px]">
          <Text
            classes="!text-2xl !font-semibold capitalize !text-typography-secondary "
            dataTestId="create-edit-delete-asset-filter-dialog-title"
          >
            {title}
          </Text>
          <IconButton
            aria-label="close"
            onClick={onClose}
            data-testid="create-edit-delete-asset-filter-dialog-close-icon-btn"
            className="!-mt-1 h-10 w-10"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <ThemeProvider theme={formTheme}>
          <form onSubmit={(e) => e.preventDefault()} noValidate>
            <DialogContent className="!p-0 !pb-[24px] overflow-hidden	">
              <Grid
                container
                className="bg-background topPaddingDrawerSection !pt-0"
              >
                {isDeleting ? (
                  <DialogContentText
                    className="text-lg text-base !text-typography-secondary"
                    id="confirmation-dialog-description"
                    data-testid="confirmation-dialog-description"
                  >
                    Are you sure you want to delete filter "{name}"?
                  </DialogContentText>
                ) : (
                  <>
                    <Grid
                      item
                      data-testid="create-edit-delete-asset-filter-name-field-wrapper"
                      xs={12}
                    >
                      <TextFieldElement
                        name="filterName"
                        label="Name"
                        type="text"
                        required
                        className="w-full"
                        control={control}
                        onChange={onFilterNameChangeHandler}
                        inputProps={{
                          "data-testid": "filter-textbox",
                          placeholder: "Enter Filter Name",
                        }}
                        onKeyDown={onFilterNameKeyDownHandler}
                        helperText={helperText}
                      />
                    </Grid>

                    <Grid item {...BREAKPOINTS} data-testid="shared-control">
                      <FormControlLabel
                        control={
                          <Checkbox
                            data-testid="shared-checkbox"
                            checked={isPublic}
                            onChange={onFilterTypeChangeHandler}
                            sx={{
                              "& input": {
                                "data-testid": "assets-filter-__ispublic",
                              },
                            }}
                          />
                        }
                        label="Shared"
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </DialogContent>
            <DialogActions
              sx={{
                margin: "0 auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  columnGap: 1,
                  height: "100%",
                }}
              >
                <TextButton
                  data-testid="cancel-action"
                  text="Cancel"
                  size="medium"
                  theme="blue"
                  disabled={isLoading}
                  onClick={onClose}
                />

                <SubmitButton
                  sx={{ padding: "24px", margin: 0 }}
                  dataTestid="submit-action"
                  text={isLoading ? actionPresentParticiple : mode}
                  disabled={isDisabled}
                  size="medium"
                  theme="blue"
                  variant="default"
                  type="submit"
                  onClick={onSaveHandler}
                />
              </Box>
            </DialogActions>
          </form>
        </ThemeProvider>
      </Dialog>
    );
  }
);

export default FilterModal;
